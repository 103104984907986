import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom'; // Import useLocation

const GridItem = ({ href, src, alt, caption, onEdit, onDelete }) => {
  const location = useLocation(); // Get location

  return (
    <div className="relative">
      {location.pathname.endsWith("admin") && (
        <div className="absolute top-2 right-2 flex space-x-1 z-50">
          <button
            onClick={onEdit}
            className="bg-blue-700 hover:bg-blue-800 text-white rounded-full p-1.5 text-xs focus:outline-none focus:ring-2 focus:ring-blue-300"
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
          <button
            onClick={onDelete}
            className="bg-red-700 hover:bg-red-800 text-white rounded-full p-1.5 text-xs focus:outline-none focus:ring-2 focus:ring-red-300"
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
        </div>
      )}
      <div className="go_gridItem">
        <img src={src} alt={alt} />
        {caption && <span className="go_caption go_caption-full">{caption}</span>}
      </div>
    </div>
  );
};

export default GridItem;
