export const images = [];

for (let i = 1; i <= 20; i++) {
  const pageNumber = String(i).padStart(4, '0'); // e.g., '0001' for page 1
  const imageName = `catlog_page-${pageNumber}.jpg`;

  // Dynamically import each image
  const imageObject = {
    original: require(`../assets/${imageName}`),
    thumbnail: require(`../assets/thumbnail/${imageName}`)
  };

  // Add the image object to the images array
  images.push(imageObject);
}
