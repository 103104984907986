import React, { useState, useEffect } from 'react';
import Spinner from '../shared/Spinner';

const BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:5000";

const Modal = ({ isOpen, onClose, onProductAdded, productToEdit, mode, showNotification }) => {
  const [product, setProduct] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [productCategory, setProductCategory] = useState('');
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (mode === 'edit' && productToEdit) {
      setProduct(productToEdit.product);
      setProductDescription(productToEdit.productDescription);
      setProductCategory(productToEdit.productCategory);
      setImage(null);
    } else if (mode === 'add') {
      setProduct('');
      setProductDescription('');
      setProductCategory('');
      setImage(null);
    }
  }, [productToEdit, mode]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('product', product);
    formData.append('productDescription', productDescription);
    formData.append('productCategory', productCategory);
    if (image) formData.append('image', image);

    setLoading(true);
    const apiUrl = mode === 'edit'
      ? `${BASE_URL}/api/products/${productToEdit._id}`
      : `${BASE_URL}/api/products`;

    fetch(apiUrl, {
      method: mode === 'edit' ? 'PUT' : 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(() => {
        onProductAdded();
        onClose();
        showNotification('Product saved successfully');
      })
      .catch(error => {
        console.error('Error:', error);
        showNotification('Error saving product', 'error');
      })
      .finally(() => setLoading(false));
  };

  const handleDelete = () => {
    setLoading(true);
    fetch(`${BASE_URL}/api/products/${productToEdit._id}`, { method: 'DELETE' })
      .then(() => {
        onProductAdded();
        onClose();
        showNotification('Product deleted successfully');
      })
      .catch(error => {
        console.error('Error:', error);
        showNotification('Error deleting product', 'error');
      })
      .finally(() => setLoading(false));
  };

  if (!isOpen) return null;

  return (
    <div
      id="default-modal"
      tabIndex="-1"
      aria-hidden="true"
      className="fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-[calc(100%-1rem)] max-h-full overflow-y-auto overflow-x-hidden flex justify-center items-center bg-black bg-opacity-40"
    >
      <div className="relative p-4 w-full max-w-2xl max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              {mode === 'edit' ? 'Edit Product' : 'Add New Product'}
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={onClose}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="p-4 md:p-5 space-y-4">
            {loading && <Spinner />}
            {!loading && (
              <form onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="product" className="block text-sm font-medium text-gray-700 dark:text-gray-400">Product Name</label>
                  <input
                    type="text"
                    id="product"
                    name="product"
                    value={product}
                    onChange={(e) => setProduct(e.target.value)}
                    className="mt-1 block w-full text-black px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
                <div>
                  <label htmlFor="productDescription" className="block text-sm font-medium text-gray-700 dark:text-gray-400">Product Description</label>
                  <input
                    type="text"
                    id="productDescription"
                    name="productDescription"
                    value={productDescription}
                    onChange={(e) => setProductDescription(e.target.value)}
                    className="mt-1 block w-full text-black px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
                <div>
                  <label htmlFor="productCategory" className="block text-sm font-medium text-gray-700 dark:text-gray-400">Product Category</label>
                  <input
                    type="text"
                    id="productCategory"
                    name="productCategory"
                    value={productCategory}
                    onChange={(e) => setProductCategory(e.target.value)}
                    className="mt-1 block w-full text-black px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
                <div>
                  <label htmlFor="image" className="block text-sm font-medium text-gray-700 dark:text-gray-400">Product Image</label>
                  <input
                    type="file"
                    id="image"
                    name="image"
                    onChange={(e) => setImage(e.target.files[0])}
                    className="mt-1 block w-full text-black px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
                <div className="flex items-center justify-end mt-4 space-x-4">
                  {mode === 'edit' && (
                    <button
                      type="button"
                      onClick={handleDelete}
                      className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                    >
                      Delete
                    </button>
                  )}
                  <button
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    {mode === 'edit' ? 'Update' : 'Submit'}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
