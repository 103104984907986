import React from "react";
import { useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import "story-show-gallery/dist/ssg.min.css";
import "story-show-gallery/dist/GridOverflow3D.min.css";
import GridItem from "./GridItem";
import Modal from "../shared/Modal";
import Spinner from "../shared/Spinner";
import "./Gallery.css";

const BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:5000";

const Gallery = () => {
  const [products, setProducts] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [productToEdit, setProductToEdit] = React.useState(null);
  const [mode, setMode] = React.useState('add');
  const [loading, setLoading] = React.useState(false);
  const location = useLocation();

  const showNotification = (message, type = 'success') => {
    if (type === 'success') {
      toast.success(message);
    } else {
      toast.error(message);
    }
  };

  const refreshProducts = () => {
    setLoading(true);
    fetch(`${BASE_URL}/api/products`)
      .then((response) => response.json())
      .then((data) => {
        setProducts(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    refreshProducts();
  }, []);

  const handleEdit = (product) => {
    setProductToEdit(product);
    setMode('edit');
    setIsModalOpen(true);
  };

  const handleDelete = (product) => {
    setLoading(true);
    fetch(`${BASE_URL}/api/products/${product._id}`, { method: 'DELETE' })
      .then(() => {
        refreshProducts();
        showNotification('Product deleted successfully');
      })
      .catch((error) => {
        console.error("Error:", error);
        showNotification('Error deleting product', 'error');
      });
  };

  const openAddModal = () => {
    setProductToEdit(null);
    setMode('add');
    setIsModalOpen(true);
  };

  return (
    <main>
      {location.pathname.endsWith("admin") && (
        <>
          <div className="flex justify-center pt-6">
            <button
              onClick={openAddModal}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              +
            </button>
          </div>
          <Modal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onProductAdded={refreshProducts}
            productToEdit={productToEdit}
            mode={mode}
            showNotification={showNotification}
          />
        </>
      )}

      {loading ? (
        <Spinner />
      ) : (
        <section>
          <div className="gridOverflow go-masonry go-3Dfx go-zoomFx go-actionIcon ssg fs ssgdim">
            {products.map((product) => (
              <GridItem
                key={product._id}
                href={product.productImage}
                src={product.productImage}
                alt={product.productCategory}
                caption={product.productCategory}
                onEdit={() => handleEdit(product)}
                onDelete={() => handleDelete(product)}
              />
            ))}
          </div>
        </section>
      )}
    </main>
  );
};

export default Gallery;
