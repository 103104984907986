import React from 'react';
import Gallery from './Gallery';

const Admin = ({ showNotification }) => {
  // Admin specific logic can go here
  return (
    <div>
      <Gallery showNotification={showNotification} />
    </div>
  );
};

export default Admin;
