import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import Home from './home';
import Gallery from './Gallery';
import Admin from './Admin';

// Function to show toast notifications
const showNotification = (message, type = 'success') => {
  if (type === 'success') {
    toast.success(message);
  } else {
    toast.error(message);
  }
};

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/grid" element={<Gallery showNotification={showNotification} />} />
          <Route path="/grid/admin" element={<Admin showNotification={showNotification} />} />
        </Routes>
      </BrowserRouter>
      {/* Include ToastContainer to display notifications */}
      <ToastContainer />
    </div>
  );
}

export default App;
